@import url('antd/dist/reset.css');

@supports (height: 100lvh) {
  :root {
    --chakra-vh: 100lvh;
  }
}


html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
}

*,
body {
  /* font-size: 5px ; */
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.ant-layout .ant-layout-content {
  /* flex: auto; */
  min-height: unset;
}

.text-primary {
  color: #3baa6f
}

.shadow-none {
  box-shadow: none;
}

.text-center {
  text-align: center;
}

.ant-btn-primary {
  background-color: #3baa6f;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: #2c8450;
}

.delete:hover {
  color: orangered;
  cursor: pointer;
}

.delete.ant-btn-default:not(:disabled):hover {
  color: orangered;
  border-color: orangered;
}

.required::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}


.sub-actions {
  color: grey;
  cursor: pointer
}

.sub-actions:hover {
  color: black
}

.table-inputs .ant-form-item {
  margin-bottom: 0;
}

.tag-actions {
  margin-left: 10px;
  display: flex;
}

.tag-actions svg {
  cursor: pointer;
}

.copyable .ant-typography {
  margin-bottom: 0;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected {
  color: #00ba71;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after {
  border-bottom-color: #00ba71;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-active::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-active::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-open::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-open::after {
  border-bottom-color: #00ba71;
}

.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected),
.ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
  color: #00ba71;
}

.heading-1 {
  font-weight: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
  font-size: 3.75rem;
}

.heading-2 {
  font-weight: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
  font-size: 3rem;
}

.heading-4 {
  font-weight: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}

.ant-layout .ant-layout-header {
  padding-inline: 5px;
}

.display-mobile-none {
  display: none;
}

.display-desktop-none {
  display: block;
}

@media screen and (min-width: 62em) {
  .ant-layout .ant-layout-header {
    padding-inline: 50px;
  }

  .heading-1 {
    font-size: 3rem;
  }

  .heading-2 {
    font-size: 2.25rem;
  }

  .display-mobile-none {
    display: flex;
  }

  .display-desktop-none {
    display: none;
  }
}

@media screen and (min-width: 48em) {
  .heading-1 {
    font-size: 3.75rem;
  }

  .heading-2 {
    font-size: 3rem;
  }

  .heading-4 {
    font-size: 1.875rem;
    line-height: 1.2;
  }
}

.chakra-text {
  font-size: 1.125rem;
}

@media screen and (min-width: 0em) and (max-width: 47.9375em) {

  .heading-1,
  .heading-2 {
    font-size: 1.875rem;
    line-height: 1.33;
  }
}

.section-home {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media screen and (min-width: 62em) {
  .section-home {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

.posts-cards .custom-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  border-radius: 0.75rem;
  box-shadow: 0px 0px 25px rgba(54, 91, 125, 0.2);

}

.posts-cards .custom-card .card-img-container {
  position: relative;
  padding: 1rem;
}

.posts-cards .custom-card .card-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 0.75rem;
}

.posts-cards .custom-card .card-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 1rem;
}

.posts-cards .custom-card {
  margin-top: 4rem;
}

.posts-cards .custom-card .card-title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.3;
  height: auto;
}

.posts-cards .custom-card .card-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: #2d3748;
}

.custom-button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: 0.5rem;
  font-weight: 600;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 2.5rem;
  min-width: 2.5rem;
  font-size: 1rem;
  -webkit-padding-start: 1rem;
  padding-inline-start: 1rem;
  -webkit-padding-end: 1rem;
  padding-inline-end: 1rem;
  background-color: #e2fbea;
  color: #3baa6f;
}

.custom-button a {
  color: #3baa6f;
}

.carousel-container {
  width: 96%;
}

@media screen and (min-width: 62em) {
  .carousel-container {
    width: 70%;
  }
}

.carousel.carousel-slider {
  overflow: visible;
}

.carousel .control-dots {
  bottom: -5rem;
}

.carousel .control-dots .dot {
  background: #a8a8a8;
  height: 10px;
  width: 10px;
  box-shadow: none;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #00BA72;
  border-radius: 18px;

  width: 24px;

}

.footer-link {
  font-size: 16px;
  margin: 5px 0;
}

.footer-link a {
  color: rgb(68, 68, 68);
}

.footer-link a:hover {
  color: #00BA72;
  /* font-weight: 600; */
  text-decoration: underline;
}

.nav-footer.ant-layout-footer {
  padding: 0;
}

.border-primary {
  border-color: #00ba71;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after {
  border-width: 4px;
}

.action-filters {
  display: flex;
  justify-content: space-between;
  min-width: 200px;
  width: fit-content;
}

.action-filters .ant-form-item {
  margin-bottom: 0;
}

.action-filters svg {
  font-size: 30px;
  cursor: pointer;
}

.action-filters .ant-form-item svg {
  font-size: 15px;
}

.ant-spin .ant-spin-dot-item {
  background-color: #00ba71;
}

.ant-spin {
  color: #00ba71;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #00ba72;
}

.rbc-show-more {
  background-color: #ffffff4d;
  z-index: 4;
  font-weight: 700;
  font-size: 85%;
  height: auto;
  line-height: normal;
  color: #00ba72;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid #008049;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #008049;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #005c35;
}

.ant-tabs .ant-tabs-ink-bar {
  background: #00ba71;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs .ant-tabs-tab:hover {
  color: #00ba71;
}

.ant-btn-primary:disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}

.post-detail-content img {
  max-width: 60%;
}

.card-img-container img {
  max-width: 100%;
}

.truncate-overflow {
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem;
  /* space for ellipsis */
}

.truncate-overflow::before {
  position: absolute;
  content: "...";
  /* tempting... but shows when lines == content */
  /* top: calc(var(--lh) * (var(--max-lines) - 1)); */

  /*
  inset-block-end: 0;
  inset-inline-end: 0;
  */
  bottom: 0;
  right: 0;
}

.truncate-overflow::after {
  content: "";
  position: absolute;
  /*
  inset-inline-end: 0;
  */
  right: 0;
  /* missing bottom on purpose*/
  width: 1rem;
  height: 1rem;
  background: white;
}

.swiper-button-container {
  width: 95%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}


.swiper {
  width: 80%;
}

@media screen and (min-width: 640px) {
  .swiper {
    width: 80%;
  }
}

@media screen and (min-width: 768px) {
  .swiper {
    width: 90%;
  }
}

@media screen and (min-width: 1024px) {
  .swiper {
    width: 96%;
  }

  .swiper-button-container {
    width: 93%;
  }
}